import parse from 'html-react-parser'
import React from 'react'
import GlobalStateContext from '../../context/globalStateContext'

export default function MoreInfo({ moreInfo, station }) {
  const { DEFAULT_STATION } = React.useContext(GlobalStateContext)

  return (
    <>
      <p className="text-sm leading-7 text-left text-gray-500">
        The seed corn maggot model (SCM) uses daily maximum and minimum soil
        temperatures to track larval development with a base 3.9C degree day
        model starting from January 1 of a given year, and is based on research
        by Broach et al. (2006). If soil temperature is not reported at chosen
        NEWA location, estimated 2-inch soil temperatures are provided by the
        Northeast Regional Climate Center for CT, DE, MA, MD, ME, NH, NY, PA,
        RI, VA, VT, and WV as of 2022.
      </p>

      <p className="text-sm leading-6 text-left text-gray-500">
        Work has been proposed by Cornell University researchers in late 2021 to
        evaluate the impact of landscape composition on SCM population dynamics.
        In response, a simple landscape composition analysis summary is also
        provided in the SCM output. These data are extracted from the 2020 USDA
        Cropland Data Layer (USDA CDL) by drawing a 5km circle around the NEWA
        location of interest and calculating the percent area of each land use
        type.
      </p>

      <ul className="px-4 mt-6 text-sm text-left list-disc">
        {moreInfo
          .filter(
            (d) =>
              d.statesOrProvinces.includes('ALL') ||
              d.statesOrProvinces.includes(
                station ? station.state : DEFAULT_STATION.state,
              ),
          )
          .map((d) => {
            return (
              <li key={d.url} className="mb-4">
                <a href={d.url} target="_blank" rel="noopener noreferrer">
                  {parse(d.title)}
                </a>
              </li>
            )
          })}
      </ul>
    </>
  )
}
