import React from 'react'

export default function References() {
  return (
    <>
      <p className="text-sm leading-6 text-left text-gray-500">
        Broatch, J. S., L. M. Dosdall, G. W. Clayton, K. N. Harker, and R.-C.
        Yang. 2006. Using Degree-Day and Logistic Models to Predict Emergence
        Patterns and Seasonal Flights of the Cabbage Maggot and Seed Corn Maggot
        (Diptera: Anthomyiidae) in Canola. ENVIRONMENTAL ENTOMOLOGY. 35: 12..
      </p>
    </>
  )
}
