import { format } from 'date-fns'
import { fahrenheitToCelcius, simpleAve } from '../../utils/helpers'

export default function seedcornMaggotLogic(
  modelData,
  data,
  dateOfInterest,
  uInput = false,
  uInput2,
) {
  const { base, elements } = modelData
  const { resultsTable } = elements

  let gdd = 0
  const dailyDataWithGdd = data.dailyData.map((d) => {
    const { tmps, temp } = d

    let wVar = temp
    if (tmps) wVar = tmps

    let dd = 'N/A'

    const tmpsFiltered = wVar.filter((t) => typeof t === 'number')
    let mintC = 'N/A'
    let maxtC = 'N/A'
    if (tmpsFiltered.length > 0) {
      mintC = fahrenheitToCelcius(Math.min(...tmpsFiltered))
      maxtC = fahrenheitToCelcius(Math.max(...tmpsFiltered))
    }
    if (mintC !== 'N/A' && maxtC !== 'N/A') {
      dd = simpleAve(mintC, maxtC, base)
    }
    if (dd !== 'N/A') gdd += dd

    return {
      date: d.date,
      server: format(d.date, 'yyyy-MM-dd'),
      dd: dd === 'N/A' ? dd : Math.round(dd),
      gdd: dd === 'N/A' ? 'N/A' : Math.round(gdd),
      isForecast: d.isForecast,
      tmpsF: d.tmps,
      tempF: d.temp,
    }
  })

  // Determine Management Guide Message ----------------------------------------
  let selectedDate = dailyDataWithGdd.find(
    (day) => day.server === dateOfInterest.server,
  )
  if (!selectedDate) {
    selectedDate = dailyDataWithGdd.find((d) => d.isForecast)
  }

  // Determine Management Guide Message ----------------------------------------
  // console.log(managementGuide)
  let managementGuide = {
    stage: '',
    management: '',
  }
  let crop = elements.managementGuide[uInput2.value]

  const gddKeys = Object.keys(crop)

  const gddRange = gddKeys.find((k) => {
    const range = k.split('|')
    const minGdd = parseInt(range[0])
    const maxGdd = parseInt(range[1])
    return selectedDate.gdd >= minGdd && selectedDate.gdd <= maxGdd
  })
  if (gddRange) {
    managementGuide.stage = crop[gddRange].stage
    managementGuide.management = crop[gddRange].management
  }

  // Datermine Results Table ---------------------------------------------------

  const resTable = dailyDataWithGdd.map((d) => {
    let generation = 'N/A'
    let peakEmergence = null
    for (const [key, val] of Object.entries(resultsTable.generation)) {
      const gddRange = key.split('-')
      const minGdd = parseInt(gddRange[0])
      const maxGdd = parseInt(gddRange[1])
      if (d.gdd >= minGdd && d.gdd <= maxGdd) {
        generation = val
        break
      }
    }
    for (const [key, val] of Object.entries(resultsTable.peakEmergence)) {
      const gddRange = key.split('-')
      const minGdd = parseInt(gddRange[0])
      const maxGdd = parseInt(gddRange[1])
      if (d.gdd >= minGdd && d.gdd <= maxGdd) {
        peakEmergence = val
        break
      }
    }
    return { ...d, generation, peakEmergence }
  })

  // Data to display in Results Table ------------------------------------------
  let tableData = [...resTable]
  if (resTable.length >= 8) {
    tableData = resTable.slice(
      dateOfInterest.dayOfYear - 3,
      dateOfInterest.dayOfYear + 5,
    )
  }
  //////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ----------------------------------------------
  const csvData = resTable.map((day) => {
    return {
      Date: format(day.date, 'MM/dd/yyyy'),
      'Daily DD': day.dd,
      'From Jan 1st': day.gdd,
      Generation: day.generation,
    }
  })
  // console.log(resTable)
  return { selectedDate, managementGuide, resultsTable: tableData, csvData }
}
