import * as React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts'

// const colors2 = [
//   '#03045eff',
//   '#023e8aff',
//   '#0077b6ff',
//   '#0096c7ff',
//   '#00b4d8ff',
//   '#48cae4ff',
// ]

/* CSS HEX */
const colors = [
  '#264653ff',
  '#2a9d8fff',
  '#8ab17dff',
  '#e9c46aff',
  '#f4a261ff',
  '#e76f51ff',
]

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  fill,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.13
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontWeight={'bold'}
      fontSize={'1em'}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const renderLegend = (props) => {
  const { payload } = props
  const data = payload.map((d) => ({
    ...d,
    name: `${d.value} - (${d.payload.pct}%)`,
  }))
  return (
    <ul className="list-disc ml-4">
      {data.map((entry, index) => (
        <li
          key={`item-${index}`}
          style={{ color: entry.color }}
          className="font-bold py-0.5"
        >
          {entry.name}
        </li>
      ))}
    </ul>
  )
}

export default function LandUseComposition({ data }) {
  const tot = data
    .slice(0, 5)
    .map((d) => d.pct)
    .reduce((acc, val) => acc + val, 0)

  const dataT = data
    .slice(0, 5)
    .map((d, i) => ({ ...d, color: colors[i] }))
    .concat({ category: 'Others', pct: 100 - tot, color: colors[5] })

  return (
    <>
      <div className="w-full sm:hidden bg-white overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <ResponsiveContainer width={'100%'} height={450}>
          <PieChart>
            <Pie
              nameKey={'category'}
              dataKey="pct"
              data={dataT}
              label={renderCustomizedLabel}
              isAnimationActive={false}
              startAngle={90}
              endAngle={-270}
            >
              {dataT.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>

            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div className="hidden sm:block w-full bg-white overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
        <ResponsiveContainer width={'100%'} height={450}>
          <PieChart margin={{ top: 0, left: 24, right: 24, bottom: 0 }}>
            <Pie
              nameKey={'category'}
              dataKey="pct"
              data={dataT}
              label={renderCustomizedLabel}
              startAngle={90}
              isAnimationActive={false}
              endAngle={-270}
            >
              {dataT.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>

            <Legend
              layout="vertical"
              align="left"
              verticalAlign="middle"
              content={renderLegend}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </>
  )
}
